import axios from '@/common/axios'
import qs from 'qs'

export function addCulture (data) {
  return axios({
    method: 'post',
    url: '/storage/culture/add',
    data: qs.stringify(data)
  })
}

export function deleteCulture (id) {
  return axios({
    method: 'delete',
    url: '/storage/culture/delete/' + id
  })
}

export function updateCulture (data) {
  return axios({
    method: 'put',
    url: '/storage/culture/update',
    data: qs.stringify(data)
  })
}

export function selectCultureInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/culture/info/' + id
  })
}

export function selectCultureList (query) {
  return axios({
    method: 'get',
    url: '/storage/culture/list',
    params: query
  })
}
