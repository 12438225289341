<template>
  <div id="culture">
    <el-dialog
      :title="cultureFormTitle"
      width="680px"
      :visible.sync="cultureDialogVisible"
      :close-on-click-modal="false"
      @close="cultureDialogClose"
    >
      <el-form
        ref="cultureFormRef"
        :model="cultureForm"
        :rules="cultureFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="品名" prop="productName">
              <el-input v-model="cultureForm.productName" placeholder="请输入品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="cultureForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源" prop="source">
              <el-input v-model="cultureForm.source" placeholder="请输入来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领回日期" prop="backDate">
              <el-date-picker v-model="cultureForm.backDate" placeholder="请选择领回日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领回数量" prop="backQuantity">
              <el-input v-model="cultureForm.backQuantity" placeholder="请输入领回数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNum">
              <el-input v-model="cultureForm.batchNum" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用日期" prop="collectionDate">
              <el-date-picker v-model="cultureForm.collectionDate" placeholder="请选择领用日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人" prop="collector">
              <el-input v-model="cultureForm.collector" placeholder="请输入领用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放人" prop="provider">
              <el-input v-model="cultureForm.provider" placeholder="请输入发放人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用数量" prop="collectionQuantity">
              <el-input v-model="cultureForm.collectionQuantity" placeholder="请输入领用数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存量" prop="balanceQuantity">
              <el-input v-model="cultureForm.balanceQuantity" placeholder="请输入结存量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="cultureForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cultureDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cultureFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="品名">
        <el-input v-model="searchForm.productName" placeholder="请输入品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="culturePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="source" label="来源" />
      <el-table-column label="领回日期">
        <template slot-scope="scope">
          <span v-if="scope.row.backDate">{{ scope.row.backDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="backQuantity" label="领回数量" />
      <el-table-column prop="batchNum" label="批号" />
      <el-table-column label="领用日期">
        <template slot-scope="scope">
          <span v-if="scope.row.collectionDate">{{ scope.row.collectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="collector" label="领用人" />
      <el-table-column prop="provider" label="发放人" />
      <el-table-column prop="collectionQuantity" label="领用数量" />
      <el-table-column prop="balanceQuantity" label="结存量" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="culturePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCulture, deleteCulture, updateCulture, selectCultureInfo, selectCultureList } from '@/api/storage/culture'

export default {
  data () {
    return {
      cultureDialogVisible: false,
      cultureFormTitle: '',
      cultureForm: {
        id: '',
        productName: '',
        spec: '',
        source: '',
        backDate: '',
        backQuantity: '',
        batchNum: '',
        collectionDate: '',
        collector: '',
        provider: '',
        collectionQuantity: '',
        balanceQuantity: '',
        remarks: ''
      },
      cultureFormRules: {
        productName: [{ required: true, message: '品名不能为空', trigger: ['blur', 'change']}]
      },
      culturePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectCultureList(this.searchForm).then(res => {
      this.culturePage = res
    })
  },
  methods: {
    cultureDialogClose () {
      this.$refs.cultureFormRef.resetFields()
    },
    cultureFormSubmit () {
      if (this.cultureFormTitle === '培养基发放使用详情') {
        this.cultureDialogVisible = false
        return
      }
      this.$refs.cultureFormRef.validate(async valid => {
        if (valid) {
          if (this.cultureFormTitle === '新增培养基发放使用') {
            await addCulture(this.cultureForm)
          } else if (this.cultureFormTitle === '修改培养基发放使用') {
            await updateCulture(this.cultureForm)
          }
          this.culturePage = await selectCultureList(this.searchForm)
          this.cultureDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cultureFormTitle = '新增培养基发放使用'
      this.cultureDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCulture(row.id)
        if (this.culturePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.culturePage = await selectCultureList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cultureFormTitle = '修改培养基发放使用'
      this.cultureDialogVisible = true
      this.selectCultureInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cultureFormTitle = '培养基发放使用详情'
      this.cultureDialogVisible = true
      this.selectCultureInfoById(row.id)
    },
    selectCultureInfoById (id) {
      selectCultureInfo(id).then(res => {
        this.cultureForm.id = res.id
        this.cultureForm.productName = res.productName
        this.cultureForm.spec = res.spec
        this.cultureForm.source = res.source
        this.cultureForm.backDate = res.backDate
        this.cultureForm.backQuantity = res.backQuantity
        this.cultureForm.batchNum = res.batchNum
        this.cultureForm.collectionDate = res.collectionDate
        this.cultureForm.collector = res.collector
        this.cultureForm.provider = res.provider
        this.cultureForm.collectionQuantity = res.collectionQuantity
        this.cultureForm.balanceQuantity = res.balanceQuantity
        this.cultureForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCultureList(this.searchForm).then(res => {
        this.culturePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCultureList(this.searchForm).then(res => {
        this.culturePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCultureList(this.searchForm).then(res => {
        this.culturePage = res
      })
    }
  }
}
</script>

<style>
</style>
